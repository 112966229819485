<template>
  <!-- 中间数据列表 start -->
  <div class="page">
    <el-table
      ref="multipleTable"
      v-loading="listLoading"
      border
      stripe
      row-key="id"
      class="table"
      header-cell-class-name="table-header"
      :header-cell-style="{ background: '##D8E5F9', color: '#333' }"
      :data="list"
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      :row-class-name="tableRowClassName"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        v-if="isIndexCol"
        type="index"
        label="序号"
        width="55"
        align="center"
      />
      <el-table-column
          v-if="isMultiple"
          type="selection"
          width="55"
          align="center"
          fixed="left"
        />
      <template v-for="(item, index) in tableColList">
        <template v-if="item.visible">
          <el-table-column
              v-if="item.type == 'selection'"
              type="selection"
              width="55"
              align="center"
              fixed="left"
              :selectable="item.selectHandle"
            />
          <el-table-column
            v-if="item.type !== 'selection'"
            :key="Math.random()+index"
            :label="item.label"
            :prop="item.prop"
            :align="item.align"
            :min-width="item.minWidth"
            :width="item.width"
            :fixed="item.fixed"
            :sortable="item.sortable"
            :type="item.type"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <!-- 单图片 -->
              <template v-if="imageArr.includes(item.prop)">
                <PreviewImage1
                  :image="scope.row[`${item.prop}`]"
                  :width="imageWidth"
                  :height="imageHeight"
                  text-align="center"
                />
                <!-- <PreviewImage3
                  :image-arr="[scope.row[`${item.prop}`]]"
                  :width="imageWidth"
                /> -->
              </template>
              <!-- 多图片 -->
              <template v-else-if="multiImageArr.includes(item.prop)">
                <div class="image-wrap">
                  <PreviewImage2 :image-arr="scope.row[`${item.prop}`]" />
                </div>
              </template>
              <!-- 长文本 例如介绍内容 -->
              <template v-else-if="longTextArr.includes(item.prop)">
                <div
                  class="long-text"
                  :style="{ 'max-height': `${longMaxHeight}px` }"
                >
                  {{ scope.row[`${item.prop}`] || "--" }}
                </div>
              </template>
              <!-- 价格 -->
              <template v-else-if="priceArr.includes(item.prop)">
                <span v-if="![null, ''].includes(scope.row[`${item.prop}`])" :class="item.class">
                  ￥{{ scope.row[`${item.prop}`] || 0.00 }}
                </span>
                <span v-else>
                  --
                </span>
              </template>
              <!-- 字段缺省时候保留0  例如订单数量 -->
              <template v-else-if="retainZeroArr.includes(item.prop)">
                <span :class="item.class">
                  {{ scope.row[`${item.prop}`] || 0 }}
                </span>
              </template>
              <!-- 自定义类型 自由组合 -->
              <template v-else-if="customArr.includes(item.prop)">
                <slot
                  :name="`cus_${item.prop}`"
                  :data="{ index: scope.$index, row: scope.row }"
                />
              </template>
              <!-- 操作区域 -->
              <template v-else-if="item.label === '操作'">
                <slot
                  name="operation"
                  :data="{ index: scope.$index, row: scope.row }"
                />
              </template>
              <!-- 普通字段(进行展示) -->
              <template v-else>
                <span :class="item.class">{{
                  scope.row[`${item.prop}`] || "--"
                }}</span>
              </template>
            </template>
          </el-table-column>
        </template>
      </template>
    </el-table>
    <!-- 中间数据列表 end -->

    <!-- 底部分页组件 start -->
    <div class="foot">
      <pagination
        v-show="sizeTotal > 0"
        :total="sizeTotal"
        :page-sizes="pageSizes"
        :page.sync="params.page"
        :limit.sync="params.limit"
        @pagination="getData"
      />
    </div>
    <!-- 底部分页组件 end -->
  </div>
</template>

<script>
/**
 * @name     表格组件
 * @intro    该组件主要是用来放置表格数据的字段
 * @data     数据值： list、tableCol
  * // 表格列
  tableCol: [
    {
      label: "名称", // 表格字段名称
      prop: "notice", // 表格字段
      align: "center",  // 列是否居中
      width: "auto",  // 宽度
      minWidth: "200",  // 最小宽度
      class: "",  // 类名
      fixed: true,  // 是否固定列 [true | 'left' | 'right']
      visible: true // 列是否可见，有时候一个列有几个字段，比如司机【姓名、电话】
    }
  ]
 * @status   状态值：暂无
 * @params   参数值：params、sizeTotal
 * @rangData 列字段所属类型 【longTextArr、multiImageArr、imageArr、priceArr、retainZeroArr、customArr】
 */
import PreviewImage1 from '@/components/PreviewImage1'
import PreviewImage2 from '@/components/PreviewImage2'
import Pagination from '@/components/Pagination'

export default {
  components: { PreviewImage1, PreviewImage2, Pagination },
  props: {
    // 是否显示加载状态
    listLoading: {
      type: Boolean,
      default: false
    },
    // 总数据
    list: {
      type: Array,
      default: () => []
    },
    // 列数据
    tableCol: {
      type: Array,
      default: () => []
    },
    // 参数
    params: {
      type: Object,
      default: () => ({})
    },
    // 总条数
    sizeTotal: {
      type: Number,
      default: 0
    },
    isIndexCol: {
      type: Boolean,
      default: false
    },
    // 是否开启多选模式
    isMultiple: {
      type: Boolean,
      default: false
    },
    // 长文本类型字段
    longTextArr: {
      type: Array,
      default: () => []
    },
    // 长文本预览最大高度
    longMaxHeight: {
      type: Number,
      default: 100
    },
    // 单/多图，列表单张显示，点击可以预览多张图片
    multiImageArr: {
      type: Array,
      default: () => []
    },
    // 单图片类型字段
    imageArr: {
      type: Array,
      default: () => []
    },
    // 单图片宽度
    imageWidth: {
      type: Number,
      default: 80
    },
    imageHeight: {
      type: Number,
      default: 80
    },
    // 钱类型字段，前面会自动加上￥,并缺省为0
    priceArr: {
      type: Array,
      default: () => []
    },
    // 缺省为保留0而不是--
    retainZeroArr: {
      type: Array,
      default: () => []
    },
    // 自己定制
    customArr: {
      type: Array,
      default: () => []
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 50]
      }
    }
  },
  watch: {
    isMultiple: {
      handler (val) {
        if (val) {
          let item = {}
          this.tableColList.push(item)
        }
      },
      immediate: true,
      deep: true
    },
    tableCol: {
      handler (val) {
          this.tableColList = val 
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      tableColList: []
    }
  },
  methods: {
    // el-table对某一行进行样式修改
    tableRowClassName({ row, rowIndex }) {
      // if (row.delFlag) {
      //   return 'warning-row'
      // }
      return ''
    },
    // 获取数据
    getData() {
      this.$emit('onGetData')
    },
    // // 禁用部分复选框
    // selectable(row, index) {
    //   if (row.role == 1) {
    //     return false
    //   } else {
    //     return true
    //   }
    // },
    // 全选-当选择项发生变化时会触发该事件
    handleSelectionChange(e) {
      // console.log('handleSelectionChange', e)
      this.$emit('onSelectionChange', e)
    }
  }
}
</script>

<style>
.warning-row{
  background-color: #FFF0F0!important;
}
</style>

<style lang="scss" scoped>
.page{
  // margin-top: 10px;
  padding: 0 10px 10px;
  background-color: #fff;
}
// 表格滚动条
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 15px; // 横向滚动条
  height: 15px; // 纵向滚动条 必写
  background-color: #f1f1f1;
}
// 滚动条的滑块
::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #ddd;
}
.long-text {
  max-height: 100px;
  overflow: auto;
}
.long-text::-webkit-scrollbar {
  width: 6px; // 横向滚动条
  height: 18px; // 纵向滚动条 必写
  background-color: #f1f1f1;
}
.long-text::-webkit-scrollbar-thumb {
  background-color: #ddd;
}
</style>
