<template>
    <div class="tags">
        <ul>
          <li class="tags-li" style="width: 48px !important;min-width: 48px;" :class="{'active': isActive('/index')}">
              <router-link to="/index" class="tags-li-title" style="width: 48px !important;min-width: 48px;margin-right: 0;">
                <i class="icon-shouye2 icon iconfont"></i>
              </router-link>
          </li>
            <li class="tags-li" v-for="(item,index) in tagsList" :class="{'active': isActive(item.path)}" :key="index">
                <router-link :to="item.path" class="tags-li-title">
                    {{item.title}}
                </router-link>
                <span class="tags-li-icon" @click="closeTags(index)"><i class="el-icon-close"></i></span>
            </li>
        </ul>
        <div class="tags-close-box">
            <el-dropdown @command="handleTags" trigger="hover" style="width: 100%;height: 100%;line-height: 40px;">
              <div >
                <i class="el-icon-arrow-down el-icon--right"></i>
              </div>
                <el-dropdown-menu size="small" slot="dropdown">
                    <el-dropdown-item command="now">关闭当前标签页</el-dropdown-item>
                    <el-dropdown-item command="other">关闭其他标签页</el-dropdown-item>
                    <el-dropdown-item command="all">关闭所有标签页</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>
<script>
    import bus from './bus';
    export default {
        data() {
            return {
                tagsList: []
            }
        },
        methods: {
            isActive(path) {
                return path === this.$route.fullPath;
            },
            // 关闭单个标签
            closeTags(index) {
                const delItem = this.tagsList.splice(index, 1)[0];
                const item = this.tagsList[index] ? this.tagsList[index] : this.tagsList[index - 1];
                if (item) {
                    delItem.path === this.$route.fullPath && this.$router.push(item.path);
                }else{
                    this.$router.push('/');
                }
            },
            // 关闭全部标签
            closeAll(){
                this.tagsList = [];
                this.$router.push('/');
            },
            // 关闭其他标签
            closeOther(){
                const curItem = this.tagsList.filter(item => {
                    return item.path === this.$route.fullPath;
                })
                this.tagsList = curItem;
            },
            // 关闭当前标签
            closeNow(){
                const curIndex = this.tagsList.findIndex(item => {
                    return item.path === this.$route.fullPath;
                })
                const delItem = this.tagsList.splice(curIndex, 1)[0]
                const item = this.tagsList[curIndex] ? this.tagsList[curIndex] : this.tagsList[curIndex - 1];
                console.log(item)
                if (item) {
                    delItem.path === this.$route.fullPath && this.$router.push(item.path);
                }else{
                    this.$router.push('/');
                }
            },
            // 设置标签
            setTags(route){
                const isExist = this.tagsList.some(item => {
                    return item.path === route.fullPath;
                })
                if(!isExist && route.fullPath != '/index'){
                    if(this.tagsList.length >= 8){
                        this.tagsList.shift();
                    }
                    this.tagsList.push({
                        title: route.meta.title,
                        path: route.fullPath,
                        name: route.matched[1].components.default.name
                    })
                }
                bus.$emit('tags', this.tagsList);
            },
            handleTags(command){
                command === 'other' ? this.closeOther() : command === 'all' ? this.closeAll() : this.closeNow();
            }
        },
        computed: {
            showTags() {
                return this.tagsList.length > 0;
            }
        },
        watch:{
            $route(newValue, oldValue){
                this.setTags(newValue);
            }
        },
        created(){
            this.setTags(this.$route);
            // 监听关闭当前页面的标签页
            bus.$on('close_current_tags', () => {
                for (let i = 0, len = this.tagsList.length; i < len; i++) {
                    const item = this.tagsList[i];
                    if(item.path === this.$route.fullPath){
                        if(i < len - 1){
                            this.$router.push(this.tagsList[i+1].path);
                        }else if(i > 0){
                            this.$router.push(this.tagsList[i-1].path);
                        }else{
                            this.$router.push('/');
                        }
                        this.tagsList.splice(i, 1);
                    }
                }
            })
        }
    }

</script>


<style>
    .tags {
        position: relative;
        height: 40px;
        overflow: hidden;
        background: #fff;
        /*box-shadow: 0 5px 10px #ddd;*/
        /* width: calc(100% - 20px); */
        width: 100%;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
    }

    .tags ul {
        box-sizing: border-box;
        width: calc(100% - 100px);
        height: 100%;
        display: flex;
    }

    .tags-li {
      min-width: 84px;
      height: 40px;
      padding: 0 8px;
      /* border-radius: 4px 4px 0px 0px; */
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      color: #333;
      -webkit-transition: all .3s ease-in;
      -moz-transition: all .3s ease-in;
      transition: all .3s ease-in;
      font-size: 12px;
    }

    .tags-li:not(.active):hover {
        background: #f8f8f8;
    }

    .tags-li.active {
        background: #E6EDFF !important;
        color: #4478FF;
    }

    .tags-li-title {
        float: left;
        line-height: 30px;
        min-width: 80px;
        max-width: 120px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 5px;
        color: #666;
    }

    .tags-li.active .tags-li-title {
        color: #4478FF;
    }
    .mbtn{
        height: 35px;
    }
    .tags-close-box {
        
        box-sizing: border-box;
        padding-top: 1px;
        text-align: center;
        width: 50px;
        height: 40px;
        background: #fff;
        box-shadow: -3px 0 15px 3px rgba(0, 0, 0, .1);
        z-index: 10;
    }

</style>
