var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"modal"}},[_c('div',{directives:[{name:"drag",rawName:"v-drag"}],staticClass:"my-dialog"},[_c('div',{staticClass:"my-dialog-mask"}),_c('div',{staticClass:"my-dialog-wrap",style:({
        position: _vm.isFullScreen ? 'static': 'relative',
        width: _vm.isFullScreen ? '100%': _vm.width,
        height: _vm.isFullScreen ? '100%': 'auto',
        borderRadius: _vm.isFullScreen ? '0': '6px',
        maxHeight: _vm.isFullScreen ? '100vh':'80vh'
      })},[_c('header',{staticClass:"my-dialog-wrap-head"},[_c('div',{staticClass:"title cus-line-1"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"ctrl"},[(!_vm.isFullScreen)?_c('i',{staticClass:"icon el-icon-full-screen",on:{"click":function($event){return _vm.setFullScreen()}}}):_c('i',{staticClass:"icon el-icon-copy-document",on:{"click":function($event){return _vm.quitFullScreen()}}}),_c('i',{staticClass:"icon el-icon-close",on:{"click":function($event){return _vm.closeDialog()}}})])]),_c('main',{staticClass:"my-dialog-wrap-body",style:({
          maxHeight: _vm.isFullScreen ? 'calc(100vh - 100px)':'calc(80vh - 50px)'
        })},[_vm._t("default"),(_vm.$slots.foot)?_c('div',{staticClass:"my-dialog-wrap-gap"}):_vm._e()],2),(_vm.$slots.foot)?_c('footer',{staticClass:"my-dialog-wrap-foot"},[_vm._t("foot")],2):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }