import { constantRoutes } from '@/router'
import { get_my_permit_api } from '@/api/system/menu'

const permission = {
  state: {
    roles: [],
    routes: [],
    addRoutes: []
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.routes = constantRoutes
      console.log('是否存入路由', state, routes)
      // state.addRoutes = routes
      // state.routes = constantRoutes.concat(routes)
    }
  },
  actions: {
    // 生成路由
    GenerateRoutes({ commit }, data) {
      commit('SET_ROUTES', [])
      // return new Promise(resolve => {
      //   // 向后端请求路由数据
      //   get_my_permit_api().then(res => {
      //     const accessedRoutes = filterAsyncRouter(res.data)
      //     accessedRoutes.push({ path: '*', redirect: '/404', hidden: true })
      //     console.log('object :>> ', fetRedirectPath(JSON.parse(JSON.stringify(res.data))));
      //     accessedRoutes.push({ path: '/', redirect: fetRedirectPath(JSON.parse(JSON.stringify(res.data))), hidden: true })
      //     commit('SET_ROUTES', accessedRoutes)
      //     resolve(accessedRoutes)
      //   })
      // })
    }
  }
}
function fetRedirectPath(router) {
  const item = router.find(e => !e.hidden && e.path)
  let path = ''
  const onePath = (route) => {
    console.log('=========', route)
    if (route && route.children != null && route.children && route.children.length) {
      if (!(new RegExp('^/.*$').test(route.path))) {
        route.path = '/' + route.path
      }
      path += route.path
      const childrenItem = route.children.find(e => !e.hidden)
      onePath(childrenItem)
    } else {
      if (!(new RegExp('^/.*$').test(route.path))) {
        route.path = '/' + route.path
      }
      path += route.path
      return path
    }
  }
  onePath(item)
  return path
}

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap, isRootMenu = true) {
  return asyncRouterMap.filter(route => {
    if (route.component) {
      // Layout组件特殊处理
      if (route.component === 'Layout') {
        if (isRootMenu) {
          route.component = Layout
        } else {
          route.component = routerView
        }
      } else {
        route.component = loadView(route.component)
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children, false)
    }
    return true
  })
}

export const loadView = (view) => { // 路由懒加载
  return (resolve) => require([`@/views/${view}`], resolve)
}

export default permission
