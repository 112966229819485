import request from "@/utils/request";

// 获取当前登录用户权限
export function get_my_permit_api(data) {
  return request({
    url: "/admin2/admingroupc/get_my_permit",
    method: "get",
    params: data
  });
}

// 获取权限定义数组
export function get_permit_api(data) {
  return request({
    url: "/admin2/admingroupc/get_permit",
    method: "get",
    params: data
  });
}

// 修改权限定义数组
export function save_permit_api(data) {
  return request({
    url: "/admin2/admingroupc/save_permit",
    method: "post",
    data
  });
}