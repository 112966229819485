import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './permission.js'
import './assets/common/fonts/iconfont.css'


import store from './store'
// import utils from './utils/utils'
import Pagination from "@/components/Pagination";
import Table from "@/components/Table/Table";
import TableWrap from "@/components/Table/TableWrap";
import BaseModal from "@/components/BaseModal";
import BaseForm from "@/components/BaseForm/index";
import CusDialog from "@/components/CusDialog/index";
import CusTips from "@/components/CusTipsModule/index";


import "@/assets/styles/index.scss";
import "@/assets/styles/wd-style.scss";

Vue.config.productionTip = false
// Vue.prototype.$utils = utils
// 图片懒加载
import VueLazyload from "vue-lazyload";

// 地图插件
import VueAMap from "vue-amap";
window._AMapSecurityConfig = {
  securityJsCode: "bf593a0be3873a91fddfc118bbf5ef70",
};
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  //修改下面的key值
  key: "7df4619fd85aca66411c0a68ac1c6214",
  plugin: [
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.PolyEditor",
    "AMap.CircleEditor",
    "AMap.Geocoder",
    "AMap.Geolocation",
    "AMap.DistrictSearch",
    "Geolocation",
  ],
  // 默认高德 sdk 版本为 1.4.4
  v: "1.4.4",
});





Vue.use(VueLazyload);

Vue.component("Pagination", Pagination);
Vue.component("Table", Table);
Vue.component("TableWrap", TableWrap);
Vue.component("BaseModal", BaseModal);
Vue.component("BaseForm", BaseForm);
Vue.component("CusDialog", CusDialog);
Vue.component("CusTips", CusTips);
Vue.use(ElementUI)


// 注册自定义指令
import custom from './directive/custom'

Vue.use(custom)

//使用钩子函数对路由进行权限跳转
// router.beforeEach((to, from, next) => {
//   const role = localStorage.getItem('username');
//   console.log(role);
//   if (!role && to.path !== '/login') {
//     console.log(909090);
//     next('/login');
//   }
// })


new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
}).$mount('#app')
