<template>
  <div class="sidebar">
    <el-menu
      class="sidebar-el-menu"
      :default-openeds="[onRoutes]"
      :collapse="collapse"
      text-color="#fff"
      active-text-color="#fff" background-color="#447AFC" unique-opened router :class="{ collapse: collapse }">
      <template v-for="item in permission_routes">
        <template v-if="item.children">
          <el-submenu :index="item.path" :key="item.path" v-if="item.permit">
            <template slot="title">
              <i class="icon iconfont" :class="item.icon"></i>
              <span slot="title">{{ item.title || item.web_name }}</span>
            </template>
            <template v-for="subItem in item.children">
              <template v-if="subItem.permit">
                <el-submenu v-if="subItem.children" :index="subItem.path" :key="subItem.path">
                  <template slot="title">{{ subItem.title || subItem.web_name }}</template>
                  <el-menu-item :class="{'is-active': threeItem.path == $route.path}" v-for="(threeItem, i) in subItem.children" :key="i" :index="threeItem.path"><i
                      class="icon iconfont" :class="threeItem.icon"></i>{{ threeItem.title || threeItem.web_name }}</el-menu-item>
                </el-submenu>
                <el-menu-item v-else :class="{'is-active': subItem.path == $route.path}" :index="subItem.path" :key="subItem.path">
                  <i class="icon iconfont icon-xiangqing"
                    style="font-size: 6px;"></i>{{ subItem.title || subItem.web_name }}
                </el-menu-item>
              </template>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.path" :key="item.path" v-if="item.permit">
            <i class="icon iconfont" :class="item.icon"></i>
            <span slot="title">{{ item.title || item.web_name }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import bus from "./bus";
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      collapse: false
    };
  },
  computed: {
    ...mapGetters(['permission_routes']),
    onRoutes() {
      return '/' + this.$route.path.replace("/", "").split('/')[0]
    }
  },
  created() {
    bus.$on("collapse", msg => {
      this.collapse = msg;
    });
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
$BgColor: $menu-theme-color; //设置左侧菜单背景色
$menuActiveTextColor: #fff; //菜单点击字体
$menuActiveBgColor: #194DD4; //菜单点击背景

.el-menu {
  width: 200px;
  display: block;
  position: absolute;
  left: 0;
  top: 80px;
  bottom: 0;
  overflow-y: scroll;
  padding-bottom: 300px;
}

.sidebar::-webkit-scrollbar {
  width: 0;
}

.sidebar-el-menu:not(.el-menu--collapse) {
  width: 100%;

}

.sidebar-el-menu {
  &.collapse {
    width: 65px;
  }
}

.sidebar>ul {
  height: 100%;
}
</style>
<style lang="scss">
$BgColor: $menu-theme-color; //设置左侧菜单背景色
$menuActiveTextColor: #fff; //菜单点击字体
$menuActiveBgColor: #194DD4; //菜单点击背景

.sidebar {
  .el-menu {
    // background: linear-gradient(180deg, #DDEDFC 0%, #F5F6FA 100%);
    background: #447AFC;

    .el-menu-item {
      text-align: left !important;

      // border-left:4px solid transparent !important;
      &:hover {
        color: $menuActiveTextColor !important;
        // background-color: $menuActiveBgColor !important;
        // border-left:4px solid $menuActiveTextColor !important;
      }
      
      &.is-active {
        color: $menuActiveTextColor !important;
        background-color: $menuActiveBgColor !important;
        // border-left:4px solid $menuActiveTextColor !important;
      }
    }

    .el-submenu {
      .el-submenu__title {
        text-align: left !important;

        // border-left:4px solid transparent !important;
        &:hover {
          color: $menuActiveTextColor !important;
          background-color: $menuActiveBgColor !important;
          // border-left:4px solid $menuActiveTextColor !important;
        }
      }

    }


    .el-scrollbar__bar.is-horizontal {
      display: none !important;
    }
  }

  .el-menu-item i,
  .el-submenu__title i {
    margin-right: 10px;
  }
}

.el-menu-item i,
.el-submenu__title i {
  color: #fff !important;
}

.el-menu-item.is-active i,
.el-submenu__title.is-active i {
  color: #fff !important;
}

.el-menu-item,
.el-submenu__title {
  &:hover {
    background-color: #194DD4 !important;

    i {
      color: #fff !important;
    }
  }
}</style>
