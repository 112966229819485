var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '60px',
})},[(_vm.image)?[_c('div',{staticClass:"image-box"},[(_vm.image)?_c('el-image',{style:(_vm.imageStyle),attrs:{"src":_vm.image,"preview-src-list":[_vm.image],"lazy":""}}):_vm._e()],1)]:[_c('img',{style:({
        margin: '5px auto 0',
        width: `80px`,
        height: `80px`,
        textAlign: 'center',
        color: _vm.textColor,
        border: _vm.imageBorder,
        fontSize: _vm.textFontSize
      }),attrs:{"src":require("@/assets/images/error.png")}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }