<template>
  <transition name="modal">
    <div class="my-dialog" v-drag>
      <div class="my-dialog-mask"></div>
      <div
        class="my-dialog-wrap"
        :style="{
          position: isFullScreen ? 'static': 'relative',
          width: isFullScreen ? '100%': width,
          height: isFullScreen ? '100%': 'auto',
          borderRadius: isFullScreen ? '0': '6px',
          maxHeight: isFullScreen ? '100vh':'80vh'
        }"
      >
        <header class="my-dialog-wrap-head">
          <div class="title cus-line-1">{{ title }}</div>
          <div class="ctrl">
            <i v-if="!isFullScreen" class="icon el-icon-full-screen" @click="setFullScreen()"></i>
            <i v-else class="icon el-icon-copy-document" @click="quitFullScreen()"></i>
            <i class="icon el-icon-close" @click="closeDialog()"></i>
          </div>
        </header>
        <main
          class="my-dialog-wrap-body"
          :style="{
            maxHeight: isFullScreen ? 'calc(100vh - 100px)':'calc(80vh - 50px)'
          }"
        >
          <slot></slot>
          <div v-if="$slots.foot" class="my-dialog-wrap-gap"></div>
        </main>
        <footer v-if="$slots.foot" class="my-dialog-wrap-foot">
          <slot name="foot">
          </slot>
      </footer>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '标题'
    },
    width: {
      type: String,
      default: '70vw'
    },
    fullScreen: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isFullScreen: false
    }
  },
  watch: {
    fullScreen: {
      handler (val) {
        console.log('当前', val)
        this.isFullScreen = val
      }
    },
    deep: true,
    immediate: true
  },
  mounted() {
    document.addEventListener('keydown', this.listenESC)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.listenESC)
  },
  methods: {
    closeDialog() {
      this.$emit('onClose')
    },
    setFullScreen() {
      this.isFullScreen = true
    },
    quitFullScreen() {
      this.isFullScreen = false
    },
    listenESC(e) {
      if (e.keyCode === 27) {
        // console.log('ESC键执行');
        this.closeDialog()
      }
    }
  }
}

</script>
<style scoped lang='scss'>
.my-dialog{
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 98;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &-mask{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: rgba(0,0,0,.5);
  }
  &-wrap{
    position: relative;
    z-index: 100;
    background-color: #fff;
    overflow: hidden;
    min-width: 30vw;
    min-height: 30vh;
    &-head{
      position: relative;
      background-color: #f4f4f4;
      width: 100%;
      height: 50px;
      text-align: left;
      &:hover{
        cursor: move;
      }
      .title{
        padding: 0 20px;
        max-width: 80%;
        font-weight: 700;
        line-height: 50px;
        font-size: 16px;
        user-select: none;
      }
      .ctrl{
        position: absolute;
        top: 50%;
        right: 20px;
        margin-top: -10px;
        .icon{
          margin-left: 20px;
          font-size: 20px;
          cursor: pointer;
          font-weight: 700;
          &:hover{
            color: #0089D2;
          }
        }
      }
    }
    &-body{
      padding: 20px;
      font-size: 14px;
      max-height: calc(80vh - 50px);
      // height: 500px;
      box-sizing: border-box;
      overflow: auto;
    }
    &-gap{
      height: 60px;
    }
    &-foot{
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 9;
      width: 100%;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      box-shadow: 0 0 5px rgba(0,0,0,.2);
    }
  }
}
 
.modal-enter-active, .modal-leave-active {
  transition: opacity .5s ease;
}
 
.modal-enter, .modal-leave-to {
  opacity: 0;
}
</style>