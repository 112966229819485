import Vue from 'vue'
import focus from './focus'
import rotate from './rotate'
import preventReClick from './preventReClick'
import drag from './drag'

const install = function(Vue) {
  Vue.directive('focus', focus)
  Vue.directive('rotate', rotate)
  Vue.directive('preventReClick', preventReClick)
  Vue.directive('drag', drag)
}

if (window.Vue) {
  window['focus'] = focus // 输入框自动聚焦
  window['rotate'] = rotate // 元素旋转
  window['preventReClick'] = preventReClick // 防止重复发送请求
  window['drag'] = drag // 拖动弹窗
  Vue.use(install)
}

export default install
