var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
    margin: '0 auto',
    width: `${_vm.width}px`,
    height: `${_vm.height}px`})},[(_vm.imageArr.length)?[_c('div',{staticClass:"image-box"},[(_vm.imageArr[0])?_c('el-image',{style:(_vm.imageStyle),attrs:{"src":_vm.imageArr[0],"preview-src-list":_vm.imageArr}}):_vm._e(),_c('div',{staticClass:"image-count"},[_vm._v("共"+_vm._s(_vm.imageArr.length || 0)+"张")])],1)]:[_c('div',{style:({
        margin: '0 auto',
        width: `${_vm.width}px`,
        height: `${_vm.height}px`,
        lineHeight: `${_vm.height}px`,
        textAlign: 'center',
        color: _vm.textColor,
        border: _vm.imageBorder,
        fontSize: _vm.textFontSize,
        borderRadius: '5px'
      })},[_vm._v(" 暂无图片 ")])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }